import { Assignments } from "classes/models/assignments/assignment.model";

export const useSortAssignments = (assignments: Assignments) => {
  return assignments.sort((a, b) => {
    // If the assignments have a display order sort by that from lowest to highest
    if (a.displayOrder != undefined && b.displayOrder != undefined) {
      if (a.displayOrder < b.displayOrder) return -1;
      if (a.displayOrder > b.displayOrder) return 1;
    }

    // Sort by dueTimestamp first then by openTimestmap
    if (a.dueTimestamp == undefined || b.dueTimestamp == undefined) return 0;

    if (a.dueTimestamp < b.dueTimestamp) return -1;

    if (a.dueTimestamp > b.dueTimestamp) return 1;

    if (a.openTimestamp == undefined || b.openTimestamp == undefined) return 0;

    if (a.openTimestamp < b.openTimestamp) return -1;

    if (a.openTimestamp > b.openTimestamp) return 1;

    return 0;
  });
};
